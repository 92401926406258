.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg {
  width: 100%;

  transform-origin: center;
  animation: rotate 2s linear infinite;
}

.circle {
  fill: none;
  stroke-width: 3;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;

  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

.centered {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;

  height: 100%;
}

.loadMessagesContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 40px;

  background-color: transparent;
}

.loadMessagesWrapper {
  width: 40px;

  animation: move 1.8s infinite;
}

.loadMessages {
  width: 7px;
  height: 7px;

  border-radius: 12px;
  background-color: var(--yellow);

  animation: width-change 1.8s infinite;
}

@keyframes move {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes width-change {
  0% {
    width: 7px;
  }

  25% {
    width: 17px;
  }

  50% {
    width: 7px;
  }

  75% {
    width: 17px;
  }

  100% {
    width: 7px;
  }
}
