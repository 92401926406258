.container {
  display: flex;
  align-items: center;

  width: fit-content;
  /* max-width: 134px; */

  max-width: 100%;
  padding: 6px 16px 6px 8px;

  cursor: pointer;

  border-radius: 6px;

  /* transition: background-color 150ms ease; */
}


.itemIcon {
  --icon-color: var(--dark-64);

  width: 20px;
  height: 20px;

  pointer-events: none;

  border-radius: 4px;

  transition: stroke 150ms ease;
}

.itemText {
  overflow: hidden;

  width: fit-content;
  max-width: 100%;
  margin-left: 10px;

  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: var(--dark-64);

  /* transition: color 150ms ease; */
}

.container:hover {
  background-color: var(--white-5);
}

.containerActive {
  background-color: var(--white-10);
}

.containerActive > .itemText {
  color: var(--white);
}

.container:hover > .itemText {
  color: var(--white);
}

.pendingApplicationsCount {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;

  width: 20px !important;
  height: 20px !important;
  margin: auto;
  margin-right: 0;
  margin-left: 8px;

  color: var(--dark) !important;
  border-radius: 40px;
  background-color: var(--main-green) !important;
}

.pendingApplicationsBannerWrapper {
  position: relative;
}

.pendingApplicationsBannerTriangle {
  position: absolute;
  top: -1px;
  right: 21px;

  width: 0;
  height: 0;

  border-right: 5px solid transparent;
  border-bottom: 5px solid #24f24d10;
  border-left: 5px solid transparent;
}

.pendingApplicationsBanner {
  margin-top: 4px;
  padding: 8px;

  color: var(--main-green) !important;
  border-radius: 8px;
  background: #24f24d10;
}
