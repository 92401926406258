.container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  padding: 7px 20px;

  cursor: pointer;
  white-space: nowrap;

  border: 0;
  border-radius: 50px;
  outline: none;
  outline-width: 0;
}

.container:disabled {
  cursor: not-allowed;
}

.label {
  user-select: none;
  text-align: center;

  color: inherit;

  transition: opacity 150ms ease, color 150ms ease;
}

.colorPrimary {
  color: var(--dark);
  background-color: var(--white);

  transition: background-color 0.15s ease;
}

.colorPrimary:hover {
  background-color: #e5e5e5; /* +10% dark */
}

.colorPrimary > .icon {
  fill: var(--dark);
}

.colorPrimary:disabled {
  color: var(--dark-32);
}

.colorPrimary:disabled > .icon {
  fill: var(--white-40);
}

.colorSecondary {
  color: var(--white);
  background-color: var(--dark-20);

  transition: background-color 150ms ease;
}

.colorSecondary:hover {
  background-color: #5a5a5a; /* +20% white */
}

.colorSecondary > .icon {
  fill: var(--white);
}

.colorSecondary:disabled {
  color: var(--white-48);
}

.colorSecondary:disabled > .icon {
  fill: var(--white-48);
}

.colorDark12 {
  color: var(--white);
  background-color: var(--dark-12);

  transition: background-color 150ms ease;
}

.colorRed {
  color: var(--white);
  background-color: var(--red);

  transition: background-color 150ms ease;
}

.colorDark12:hover {
  background-color: var(--dark-20);
}

.colorDark12 > .icon {
  fill: var(--white);
}

.colorDark12:disabled {
  color: var(--white-48);
}

.colorDark12:disabled > .icon {
  fill: var(--white-48);
}

.colorGreen {
  color: var(--dark);
  background-color: var(--green);

  transition: background-color 150ms ease;
}

.colorGreen:hover {
  background-color: #50f571; /* +20% white */
}

.colorGreen > .icon {
  fill: var(--white);
}

.colorGreen:hover {
  background-color: #50f571;
}

.colorGreen > .icon {
  fill: var(--white);
}

.colorGreen10 {
  color: var(--dark);
  background-color: var(--green);

  transition: background-color 150ms ease;
}

.colorGreen10:hover {
  background-color: #50f571; /* +20% white */
}

.colorGreen10 > .icon {
  fill: var(--white);
}

.colorGreen10 {
  color: var(--green);
  background-color: var(--green-10);

  transition: background-color 50ms ease;
}

.colorGreen10:hover {
  background-color: var(--green-20);
}

.colorGreen10 > .icon {
  fill: var(--white);
}

.colorBlack {
  color: var(--green);
  background-color: var(--dark);

  transition: background-color 150ms ease;
}

.colorBlack:hover {
  background-color: #373737; /* +20% white */
}

.colorBlack > .icon {
  fill: var(--white);
}

.colorTransparent {
  background-color: transparent;
}

.colorTransparent > .label {
  color: var(--dark-64);
}

.colorTransparent:hover > .label {
  color: var(--dark-48);
}

.disabled {
  opacity: 0.5;
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;

  display: none;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  cursor: wait;

  transition: opacity 150ms ease;
}

.loadingIcon {
  border-radius: 50%;
  background-color: var(--dark-12);
}

.icon {
  flex-shrink: 0 !important;

  width: 24px;
  height: 24px;
  margin-right: 2px;

  transition: fill 150ms ease, fill 150ms ease;
}

.iconSolo {
  width: 16px;
  height: 16px;

  transition: fill 150ms ease;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px;

  cursor: pointer;

  border: 0;
  border-radius: 12px;
  outline: none;
  outline-width: 0;
}

.iconContainerSmall {
  padding: 8px;

  border-radius: 8px;
}

.iconContainerSmall > .iconSolo {
  width: 15px;
  height: 15px;
}

.iconContainerCircle {
  border-radius: 50%;
}

.iconContainerPrimary {
  background-color: var(--martinique);

  transition: background-color 150ms ease;
}

.iconContainerPrimary > .iconSolo {
  fill: var(--black-10);
}

.iconContainerPrimary:hover {
  background-color: var(--green-48);
}

.iconContainerPrimary:hover > .iconSolo {
  fill: var(--white-32);
}

.iconContainerPrimary:disabled {
  background-color: #797fab20;
}

.iconContainerPrimary:disabled > .iconSolo {
  fill: var(--white-40);
}

.iconContainerPrimary:active > .iconSolo {
  fill: var(--white);
}

.iconContainerSecondary {
  box-sizing: border-box;

  border: none;
  background-color: var(--white-10);

  transition: border-color 150ms ease, background-color 150ms ease;
}

.iconContainerSecondary > .iconSolo {
  fill: var(--white);
}

.iconContainerSecondary:hover {
  background-color: var(--white-32);
}

.iconContainerSecondary:hover > .iconSolo {
  fill: var(--white);
}

.iconContainerSecondary:focus {
  border-color: var(--yellow);
}

.iconContainerSecondary:focus > .iconSolo {
  fill: var(--yellow);
}

.iconContainerSecondary:disabled {
  border-color: var(--white-40);
}

.iconContainerSecondary:disabled > .iconSolo {
  fill: var(--white-40);
}

.iconContainerSecondary:active {
  border-color: var(--green-48);
  background-color: var(--green-48);
}

.iconContainerSecondary:active > .iconSolo {
  fill: var(--white);
}

.iconContainerColorDisabled {
  box-sizing: content-box;

  border: 1px solid var(--white-40);
  background-color: transparent;

  transition: border-color 150ms ease, background-color 150ms ease;
}

.iconContainerColorDisabled > .iconSolo {
  fill: var(--white-40);
}

.iconContainerColorDisabled:hover {
  border-color: var(--white-32);
}

.iconContainerColorDisabled:hover > .iconSolo {
  fill: var(--white-40);
}

.iconContainer:disabled {
  cursor: not-allowed;
}

.iconContainerColorDisabled:disabled {
  cursor: pointer;

  border-color: var(--yellow);
}

.iconContainerColorDisabled:disabled > .iconSolo {
  fill: var(--yellow);
}

.iconContainerColorDisabled:active {
  border-color: var(--yellow);
  background-color: var(--yellow);
}

.iconContainerColorDisabled:active > .iconSolo {
  fill: var(--white);
}
/*
@media (min-width: 1366px) and (max-width: 1920px) {
  .container {
    padding: 8px;
  }

  .icon {
    width: calc(1.3 * var(--text-regular));
    height: calc(1.3 * var(--text-regular));
  }
}

@media (max-width: 1366px) {
  .container {
    padding: 7px 8px;
  }

  .icon {
    width: calc(1.3 * var(--text-small));
    height: calc(1.3 * var(--text-small));
  }
} */

.option {
  display: flex;
  align-items: center;

  min-height: 32px;
  padding: 8px 12px;
}

.selectContainer {
  position: relative;
}

.select {
  position: absolute;
  z-index: 2;
  bottom: -18px;
  left: 17%;

  visibility: hidden;

  width: 120px;
  max-height: 128px;
}

.selectOpen {
  visibility: visible;
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 32px;
  padding: 8px 12px;

  text-align: center;
}

.buttonIconContainer {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  cursor: pointer;

  border: 0;
  border-radius: 16px;
  outline: none;
  outline-width: 0;
  background-color: var(--dark-12);
  box-shadow: var(--inset-shadow);

  transition: background-color 150ms ease;
}

.buttonIconContainer:hover {
  background-color: var(--dark-20);
}

.buttonIcon {
  flex-shrink: 0 !important;

  width: 16px;
  height: 16px;
}
