.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}

.label {
  margin-bottom: 8px;

  user-select: none;

  color: var(--white-32);
}

.socialLinks {
  display: flex;
  gap: 64px;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  border-radius: 4px;
  background-color: var(--white-10);
}

.iconContainer:hover {
  background-color: var(--white-32);
}

.iconContainer > svg:not(.smallIcon) {
  width: 20px;
  height: 20px;
}

.smallIcon {
  width: 20px;
  height: 20px;
}

.commonLink {
  display: flex;
  align-items: center;

  min-height: 32px;
  padding: 8px 12px;
}

.moreInfo {
  margin: auto;
  margin-right: 0;
}

.language {
  margin: auto;
  margin-right: 0;
}

.rightSection,
.leftSection {
  display: flex;
  gap: 8px;
}
