.appWrapper {
  display: flex;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
}

.app {
  display: flex;
  justify-content: center;

  width: 100%;
}

.wrapper {
  position: relative;

  display: flex;
  /* overflow-y: hidden; */
  flex-direction: column;

  width: 100%;
  /* height: 100vh; */
  height: 100%;
  min-height: 100vh;
}

.scrollContentWrapper {
  padding: 0 24px;
}

.mainWrapper {
  display: flex;

  width: 100%;
  /* max-width: var(--content-width); */
  min-width: var(--min-content-wrapper-width);
  height: 100%;
  padding-right: 22px;
}

.main {
  position: relative;

  display: flex;
  /* overflow: hidden; */
  flex-direction: column;

  width: 100%;
  max-width: var(--app-content-width);
}

.headerWrapper {
  position: fixed;
  z-index: var(--header-z);
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;

  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: var(--app-content-width);
  height: var(--app-header-height);
  margin: 0 16px;
}

.leftWrapper {
  position: sticky;
  z-index: 2;
  top: 0;

  display: flex;

  /* overflow-y: visible; */
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;

  width: 100%;
  max-width: var(--app-navigation-width);
  height: 100%;
  max-height: 100vh;
  padding: 22px;
}

.mainLogo {
  cursor: pointer;
}

.left {
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  flex-grow: 1;
}

.rightWrapper {
  position: sticky;
  top: 0;

  display: flex;
  /* overflow-x: hidden; */
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 2;

  width: 100%;
  max-width: var(--app-right-section-width);
  height: 100%;
  max-height: calc(100vh - var(--app-header-height));
  padding-right: 16px;
  padding-left: var(--app-right-section-gap);

  background-color: var(--color-app-bg);
}

.right {
  position: sticky;
  top: var(--app-header-height);

  display: flex;
  /* overflow-x: hidden; */

  flex-direction: column;
  flex-grow: 1;

  height: fit-content;
  max-height: calc(100vh - var(--app-header-height));
  gap: 20px;

  /* margin: auto;
  margin-right: 0;
  margin-left: 0; */
}

.content {
  position: relative;
  /* z-index: 99; */

  display: flex;
  /* overflow: hidden; */
  flex-direction: column;
  flex-grow: 2;
  flex-shrink: 1;

  width: 100%;
  max-width: var(--app-center-content-width);
  height: 100%;
  /* min-height: 100vh; */

  padding-right: 0;

  /* padding-top: 20px; */
}

.contentExtended {
  max-width: var(--app-content-width);
}

.layoutHeader {
  position: sticky;
  z-index: 25;
  top: 0;

  display: flex;

  width: 100%;
  height: var(--app-header-height);
  padding: 20px 0;

  background-color: var(--color-app-bg);
}

.contentWrapper {
  padding: 24px;

  box-shadow: var(--inset-shadow);
}

.themeHeaderWrapper {
  position: relative;

  height: 100%;
  margin-bottom: 20px;
  padding-top: 20px;

  border-radius: 0 0 16px 16px;
  background-color: var(--dark-08);
  /* box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.08); */
}

.themeHeader {
  position: sticky;
  z-index: 23;
  top: var(--app-header-height);

  background-color: var(--dark);
}

.headerThemeImageWrapper {
  width: 100%;
  height: var(--theme-header-height-px);

  border-radius: 16px 16px 0 0;
  background-position: 0% 20%;
  background-size: cover;
}

.headerThemeImage {
  height: var(--theme-header-height-px);

  border-radius: 16px 16px 0 0;
  background: linear-gradient(
    180deg,
    rgba(22, 22, 22, 0) 0%,
    rgba(22, 22, 22, 0.0086472) 6.67%,
    rgba(22, 22, 22, 0.03551) 13.33%,
    rgba(22, 22, 22, 0.0816599) 20%,
    rgba(22, 22, 22, 0.147411) 26.67%,
    rgba(22, 22, 22, 0.231775) 33.33%,
    rgba(22, 22, 22, 0.331884) 40%,
    rgba(22, 22, 22, 0.442691) 46.67%,
    rgba(22, 22, 22, 0.557309) 53.33%,
    rgba(22, 22, 22, 0.668116) 60%,
    rgba(22, 22, 22, 0.768225) 66.67%,
    rgba(22, 22, 22, 0.852589) 73.33%,
    rgba(22, 22, 22, 0.91834) 80%,
    rgba(22, 22, 22, 0.96449) 86.67%,
    rgba(22, 22, 22, 0.991353) 93.33%,
    #161616 100%
  );
}

.stickyHeaderChildren {
  z-index: 10;

  margin-top: 96px;
}

.stickyHeader {
  position: sticky;
  z-index: 23;
  top: var(--app-header-height);

  background-color: var(--dark-08);
}

.headerImageWrapper {
  position: absolute;
  top: 0;

  width: 100%;
  height: 130px;

  background-color: var(--color-app-bg);
}

.editHeader {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  cursor: pointer;

  border-radius: 20px 20px 0 0;
  background: radial-gradient(
    50.93% 100% at 50% 0%,
    rgba(5, 5, 5, 0) 0%,
    #161616 100%
  );
  gap: 8px;
}

.editHeader > svg {
  stroke: var(--dark-64);
}

.editHeader > span {
  color: var(--dark-64);
}

.headerImage {
  width: 100%;
  height: 100%;

  border-radius: 20px 20px 0 0;
  /* background: linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, rgba(22, 22, 22, 0.0086472) 10%, rgba(22, 22, 22, 0.03551) 13.33%, rgba(22, 22, 22, 0.0816599) 20%, rgba(22, 22, 22, 0.147411) 26.67%, rgba(22, 22, 22, 0.231775) 33.33%, rgba(22, 22, 22, 0.331884) 40%, rgba(22, 22, 22, 0.442691) 46.67%, rgba(22, 22, 22, 0.557309) 53.33%, rgba(22, 22, 22, 0.668116) 60%, rgba(22, 22, 22, 0.768225) 66.67%, rgba(22, 22, 22, 0.852589) 73.33%, rgba(22, 22, 22, 0.91834) 80%, rgba(22, 22, 22, 0.96449) 86.67%, rgba(22, 22, 22, 0.991353) 93.33%, #161616 100%), url('https://storage.googleapis.com/lfgcdn/assets/bg2/light-lol.webp'); */
  background-position: 0 20% !important;
  background-position-x: center !important;
  background-size: cover !important;
}

.editableHeaderImage {
  cursor: pointer;
}

.separatorHeader {
  z-index: 26;
  /* position: absolute; */
  display: none;

  height: 1px;
  margin: 0;
  padding: 0;

  background-color: var(--white-8);
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;
}

.notificationIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  border-radius: 16px;
  background-color: var(--dark-12);
  box-shadow: var(--inset-shadow);
}

.notificationIcon {
  stroke: var(--white);
}

.userAvatar {
  width: 32px;
  height: 32px;
}

.rightSection {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;

  margin-left: 12px;
  gap: 24px;
}

.rightSectionWithLogin {
  gap: 16px;
}

.headerTitle {
  font-weight: 700;

  color: var(--white);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  /* min-height: calc(60vh - var(--app-header-height)); */
}

.authButton {
  flex-grow: 1;

  width: auto;

  white-space: nowrap;
}

.headerButtons {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;

  width: 100%;
  gap: 12px;
}

.profileButtons {
  gap: 8px;
}

.moreInfo {
  margin-top: 19px;

  cursor: pointer;

  color: var(--white-48);
}

.moreInfo:hover {
  text-decoration: underline;
}

.themeBackground {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;

  width: 100%;
  height: 100vh;

  user-select: none;
  pointer-events: none;

  background: linear-gradient(
    to right,
    transparent 0%,
    transparent 50%,
    var(--dark) 100%
  );
}

.themeImage {
  position: absolute;
  z-index: -2;
  top: 0;
  right: 0;

  height: 100vh;

  user-select: none;
  pointer-events: none;
}
