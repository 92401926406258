.moreInfo {
  position: relative;
}

.list {
  position: absolute;
  z-index: 2;
  bottom: 28px;
  left: -50%;

  visibility: hidden;

  width: fit-content;
  min-width: 39px;
  max-height: 150px;
}

.listOpen {
  visibility: visible;
}

.listItem {
  width: fit-content;
  height: 32px;
  padding: 8px 12px;
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  cursor: pointer;

  border-radius: 4px;
  background-color: var(--white-10);
  gap: 2px;
}

.dots:hover {
  background-color: var(--white-32);
}

.activeInfo {
  background-color: var(--white-32);
}
