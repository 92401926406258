.container {
  display: flex;
  overflow-y: scroll;
  flex-direction: column;

  height: 100%;
}

.container::-webkit-scrollbar {
  display: none;
}

.tabsContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  margin: 0;
  margin-bottom: 32px;
  padding: 0;
  gap: 8px;
}

.logoContainer {
  width: 39px;
  height: 24px;
  margin-bottom: 40px;
  margin-left: 8px;
  /* margin-left: var(--navbar-indent); */
}

.menuTitle {
  margin-left: 8px;
}

.guildButton {
  display: flex;
  justify-content: flex-start !important;

  margin-bottom: 32px;
  padding-left: 8px !important;

  cursor: pointer;

  background-color: #0000 !important;
  gap: 8px;
}

.tabLabel {
  font-weight: 400 !important;

  text-align: start;
  white-space: break-spaces;

  color: inherit !important;
}

.guildButton:hover {
  background-color: #0000 !important;
}

.guildButton > * {
  color: var(--white-48) !important;
  stroke: var(--white-48) !important;
}

.guildButton:hover > * {
  color: var(--white) !important;
  stroke: var(--white) !important;
}

.gamesNav {
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
}

.gamesNav::-webkit-scrollbar {
  display: none;

  width: 0;
}
