.container {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  flex-grow: 1;

  width: 100%;
  max-width: 200px;
  height: 32px;
  padding: 8px;

  cursor: text;

  border-radius: 62px;
  background-color: var(--dark-12);
  box-shadow: var(--inset-shadow);

  transition: max-width 100ms ease;
}

.inputLong {
  max-width: 100%;
}

.icon {
  flex-shrink: 0;

  width: 16px;
  height: 16px;
  margin-right: 8px;
  stroke: var(--dark-48);

  transition: stroke 100ms ease;
}

.inputContainer {
  font-size: 13px;
  line-height: 18px;

  flex-grow: 1;

  width: 100%;
  height: 18px;
  padding-right: 20px;

  color: var(--white);
  border: none;
  outline: none;
  background-color: transparent;
}

.inputContainer::placeholder {
  color: var(--dark-48);
}

.inputContainer:focus ~ .icon {
  stroke: var(--white);
}

.closeContainer {
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 8px;

  width: 16px;
  height: 16px;

  cursor: pointer;
  stroke: rgba(255, 255, 255, 0.6);

  transition: stroke 100ms ease;
}

.closeContainer:hover {
  stroke: var(--white);
}

.closeHidden {
  visibility: hidden;
}
